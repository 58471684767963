import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/J3Z0dEEbGHY">
    <SEO title="Now... - Romans: Practice" />
  </Layout>
)

export default SermonPost
